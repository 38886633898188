import * as React from 'react'
import { WorkElement } from './../types/WorkElement'

export const WorkDetail: React.FunctionComponent<WorkDetailProps> = ({work}) => {
  if (work)
    return (
      <div className="edrig-work__current">
        <div className="edrig-work__current-header">
          <h2>{ work.title }</h2>
          <h3>({ work.year })</h3>
          <p className="edrig-work__current-subtitle" dangerouslySetInnerHTML={{__html: work.subTitle}} />
        </div>
        <div className="edrig-work__current-content">
          {
            work.videos?.map(video =>
              video.isEmbed &&
              <div className={`edrig-work__item_video${video.isVimeo ? ' edrig-work__item-vimeo' : ''}${video.isDailyMotion ? ' edrig-work__item-dailymotion' : ''}`}>
                <iframe src={ video.url } width="100%" height="100%" frameBorder="0" allowFullScreen></iframe>
              </div>,
            )
          }
          {
            (work.audio && work.audio.isEmbed) &&
            <div className="edrig-work__item_audio">
              <iframe width="100%" height="100%" scrolling="no" frameBorder="0" src={ work.audio.url }></iframe>
            </div>
          }
          {
            work.link &&
            <div className="edrig-work__item_link">
              <a href={work.link} target="_blank" rel="noopener noreferrer">
                <img src={work.imageUrl} alt={work.title} />
              </a>
            </div>
          }
        </div>
      </div>
    )

  return <></>
}

type WorkDetailProps = {
  work: WorkElement | undefined
}
