import React from 'react'
import Helmet from 'react-helmet'

import { GenericLayout } from '../layouts/generic'
import { Modal } from '../components/Modal'
import { WorkDetail } from '../components/WorkDetail'

import AppContext from '../context/AppContext'

import { works } from '../data/Work'
import './../styles/app.scss'


export default class Work extends React.Component<WorkProps, {}> {
  constructor(props: WorkProps) {
    super(props)
  }

  public render() {
    return (
      <GenericLayout>
        <Helmet>
          <title>Edouard Rigaudière - Film Composer</title>
          <meta property="og:title" content="Edouard Rigaudière - Film Composer" />
          <meta name="twitter:title" content="Edouard Rigaudière - Film Composer" />
        </Helmet>
        <AppContext.Consumer>
        {ctx => (
          <>
            <main className="edrig-work">
              <div className="edrig-sections edrig-tiles">
                { works.map((work, index) => {
                  return (
                    <div className="edrig-section_image"
                         key={ index }
                         onClick={ () => ctx.setCurrentWork(work) }>
                      <img src={ work.imageUrl } alt={ work.title } loading="lazy" />
                    </div>
                  )
                })}
              </div>
            </main>
            <Modal
              isOpen={ ctx.currentWork !== undefined }
              onClose={() => ctx.setCurrentWork(undefined)}>
              <WorkDetail work={ctx.currentWork} />
            </Modal>
          </>
          )}
        </AppContext.Consumer>
      </GenericLayout>
    )
  }
}

type WorkProps = {}
