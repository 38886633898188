import { WorkElement } from './../types/WorkElement'

const works: WorkElement[] = [
  {
    id: 'follow',
    title: 'Follow',
    subTitle: 'Directed by Louis Farge<br/>13 ème Rue / Universal +<br/>Produced by Bonne Pioche',
    year: 2023,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/FOLLOW.png',
    videos: [
      {
      url: 'https://www.youtube.com/embed/videoseries?si=qmyFbuxhURtMB0tv&amp;list=OLAK5uy_kBUKMSWTwMGVB7AygB99g3hXnldT_gWF8',
      isEmbed: true,
    },
    {
      url: 'https://www.youtube.com/embed/DbE0YIQ-dyY?rel=0&amp;showinfo=0&ab_channel=UniversalPlusFrance',
      isEmbed: true,
    }],
  },
  {
    id: 'souslaseine',
    title: 'Sous La Seine',
    subTitle: 'Directed by Xavier Gens<br/>Netflix</br>Produced by Let Me Be & Same Players<br/><br/>OST available soon',
    year: 2024,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/SOUS_LA_SEINE.png',
    videos: [{
      url: 'https://www.youtube.com/embed/HPfkQ9gMLMY?rel=0&amp;showinfo=0&ab_channel=NetflixFrance',
      isEmbed: true,
    }],
  },
  {
    id: 'takeachance',
    title: 'Take A Chance',
    subTitle: 'Directed by Maria Thulin<br/>Prime Video',
    year: 2023,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/TAKE_A_CHANCE.png',
    videos: [{
      url: 'https://www.youtube.com/embed/sDVMoXFyoEA?rel=0&amp;showinfo=0&ab_channel=PrimeVideoSverige',
      isEmbed: true,
    }],
  },
  {
    id: 'lesamateurs',
    title: 'Les Amateurs',
    subTitle: 'Directed by Fred Scotlande, starring François Damiens & Vincent Dedienne<br/>Disney Plus<br/>Produced by Calt & BBC<br/><br/>',
    year: 2022,
    link: 'https://boriginal.lnk.to/lesamateurs',
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/LES_AMATEURS.jpg',
    videos: [{
      url: 'https://www.youtube.com/embed/eXA9EGQfjhE?rel=0&amp;showinfo=0',
      isEmbed: true,
    }],
  },
  {
    id: 'cuisineinterne',
    title: 'Cuisine Interne',
    subTitle: 'Directed by Louis Farge<br/>13 ème Rue / Universal + <br/>Produced by Cinétévé prod<br/><br/><a href="https://boriginal.lnk.to/CuisineInterne" target="_blank">Soundtrack</a>',
    year: 2022,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/CUISINE_INTERNE.jpg',
    videos: [{
      url: 'https://www.dailymotion.com/embed/video/x8frnsn',
      isEmbed: true,
      isDailyMotion: true,
    },
    {
      url: 'https://player.vimeo.com/video/776600150',
      isEmbed: true,
      isVimeo: true,
    }],
  },
  {
    id: 'ghostland',
    title: 'Ghostland',
    subTitle: 'Directed by Pascal Laugier<br/>\"Grand Prix de Gerardmer 2018\"<br/><br/>Produced by Mars Films & 5656 films<br/>Official Score released at Omega Productions',
    year: 2017,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/GHOSTLAND.png',
    videos: [{
      url: 'https://www.youtube.com/embed/_3MDoQlAfbQ?rel=0&amp;showinfo=0',
      isEmbed: true,
    }],
    audio: {
      url: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1053488638&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
      isEmbed: true,
    },
  },
  {
    id: 'ldcn',
    title: 'Loin de chez Nous',
    subTitle: 'Directed by Fred Scotlande<br/>(10x22\')<br/><br/>Produced by CALT',
    year: 2016,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/LOIN_DE_CHEZ_NOUS.png',
    videos: [
      {
        url: 'https://player.vimeo.com/video/258548826',
        isEmbed: true,
        isVimeo: true,
      },
      {
        url: 'https://www.youtube.com/embed/mRqWO8c-LgQ?rel=0&amp;showinfo=0',
        isEmbed: true,
      },
    ],
  },
  {
    id: 'ilsetaientdix',
    title: 'Ils Etaient Dix',
    subTitle: 'Directed by Pascal Laugier<br/>TV adaptation of Agatha Christie\'s \"Then They Were None\" (6x52\')<br><br>To be released on M6 in 2021.<br/>Original Score composed with Anthony D\'amario.<br/><br/>Produced by Escazal Films.',
    year: 2020,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/ILS_ETAIENT_DIX.png',
    videos: [{
      url: 'https://www.youtube.com/embed/GVZoV7LnXDM?rel=0&amp&showinfo=0',
      isEmbed: true,
    }],
    audio: {
      url: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1290284947&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
      isEmbed: true,
    },
  },
  {
    id: 'terralibre',
    title: 'Terra Libre',
    subTitle: 'Directed by Gert-Peter Bruch',
    year: 2019,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/TERRA_LIBRE.png',
    videos: [{
      url: 'https://www.youtube.com/embed/LzvFZHE-wYM?rel=0&amp;showinfo=0',
      isEmbed: true,
    }],
    audio: {
      url: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/477269766&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
      isEmbed: true,
    },
  },
  {
    id: 'contesurmoi',
    title: 'Conte sur Moi',
    subTitle: 'Directed by Jonas Bloquet',
    year: 2019,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/CONTE_SUR_MOI.png',
    link: 'https://www.imdb.com/title/tt5844806/?ref_=nm_flmg_com_14',
  },
  {
    id: 'throughmyeyes',
    title: 'Through My Eyes',
    subTitle: 'Directed by Emerantiane Bouillon, Céleste Devisme, Naomi Gastel, Aude Portales & Ilona Quenea',
    year: 2020,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/THROUGH_EYES.png',
    audio: {
      url: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/888458584&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
      isEmbed: true,
    },
    videos: [{
      url: 'https://www.youtube.com/embed/eXkgin61Z3I?rel=0&amp;showinfo=0',
      isEmbed: true,
    }],
  },
  {
    id: 'lessacrifies',
    title: 'Les Sacrifiés',
    subTitle: 'Directed by Jordan Pavlik',
    year: 2020,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/LES_SACRIFIES.png',
    videos: [{
      url: 'https://www.youtube.com/embed/NnTLqE0Ad6E?rel=0&amp;showinfo=0',
      isEmbed: true,
    }],
    audio: {
      url: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1276258513&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
      isEmbed: true,
    },
  },
  {
    id: 'mechanicallullaby',
    title: 'Mechanical Lullaby',
    subTitle: 'Directed by Flore Blondeau, Léa Gaudin, Lucas Hoarau, Laura Keuk, Simon Reichel, Corentin Zamozik',
    year: 2020,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/MECHANICAL_LULLABY.png',
    audio: {
      url: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/888458638&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
      isEmbed: true,
    },
    videos: [{
      url: 'https://www.youtube.com/embed/S9yCBvkN5k0?rel=0&amp;showinfo=0',
      isEmbed: true,
    }],
  },
  {
    id: 'lesdernierssoleils',
    title: 'Les Derniers Soleils',
    subTitle: 'Directed by Arthur Mercier',
    year: 2019,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/LES_DERNIERS_SOLEILS.png',
    audio: {
      url: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/1154781103&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
      isEmbed: true,
    },
  },
  {
    id: 'artifice',
    title: 'Artifice',
    subTitle: 'Directed by Judicaël Ceva, Adrien Douay, Caroline Hun, Diana Lao & Coline Della Siega',
    year: 2019,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/ARTIFICE.png',
    videos: [{
      url: 'https://player.vimeo.com/video/352044818',
      isEmbed: true,
      isVimeo: true,
    }],
  },
  {
    id: 'thewreck',
    title: 'The Wreck',
    subTitle: 'Directed by Quentin Batsal, William Baud, Manon Berthelot, Angélique Busca, Nathaly Vienne',
    year: 2019,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/THE_WRECK.png',
    audio: {
      url: 'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/888458632&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true',
      isEmbed: true,
    },
    videos: [{
      url: 'https://www.youtube.com/embed/iPqkvd0_Jns?rel=0&amp;showinfo=0',
      isEmbed: true,
    }],
  },
  {
    id: 'zarae',
    title: 'Zarae',
    subTitle: 'Directed by Samy Seffadj, Camille Maupoil, Mélanie Gasteble & Tanguy Girard',
    year: 2018,
    imageUrl: 'https://static.cnginr.pw/ed-rig-com/images/works/ZARAE.png',
    videos: [{
      url: 'https://www.youtube.com/embed/YbXzHY9mYZQ?rel=0&amp;showinfo=0',
      isEmbed: true,
    }],
  },
]

export { works }
