import React from 'react'

export class Modal extends React.Component<ModalProps> {
  constructor(props: ModalProps) {
    super(props)
  }

  public render() {
    return this.props.isOpen ?
      <span className="modal_wrapper">
        <i className="edrig-x modal_close" onClick={ () => this.props.onClose() }></i>
        <span className="modal__underlay" onClick={ () => this.props.onClose() } style={{ backgroundImage: `url(${this.props.backgroundImage})`}}></span>
        <div className="modal">
          { this.props.children }
        </div>
      </span>
      : <></>
  }
}

type ModalProps = {
  isOpen: boolean
  onClose: () => void
  backgroundImage?: string
}
